<template>
  <div :class="{ 'mt-4': margin }">
    <label 
      :id="name"
      v-if="label"
      class="block text-xs font-normal text-gray-700 antialiased"
    >
      {{ label }}
    </label>
    <div class="relative" :class="{'mt-1': label }">
      <div v-if="prefix" class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span class="text-gray-500 sm:text-sm">
          {{ prefix }}
        </span>
      </div>
      <input 
        :name="name"
        :type="type"
        v-bind:value="value"
        :disabled="disabled"
        @change="$emit('change')"
        :placeholder="placeholder" 
        v-on:input="$emit('input', $event.target.value)"
        :autocomplete="autocomplete === 'off' ? name+Math.floor(Math.random() * 1000) : autocomplete"
        class="w-full border bg-gray-50 border-gray-300 focus:ring-primary focus:border-primary rounded-md shadow-sm pl-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm"
        :class="{
          'pl-9': prefix
        }"
      />
      <div v-if="suffix" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <span class="text-gray-500 sm:text-sm">
          {{ suffix }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: '' },
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    name: { type: String, required: true },
    type: { type: String, default: 'text' },
    value: { type: String, required: true },
    margin: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    autocomplete: { type: String, default: 'off' },
  }, 
}
</script>